import React from "react";
import { Typography, Toolbar } from "@material-ui/core";

const Error = ({ error }) => {
  return (
    <center>
      <Toolbar />
      <Typography color="primary" variant="h1">
        Oops!
      </Typography>
      <Typography variant="h6">Something went wrong</Typography>
      <Typography variant="body1">
        {error.graphQLErrors.map(({ message }, i) => (
          <span key={i}>{message}</span>
        ))}
      </Typography>
    </center>
  );
};

export default Error;
