import React from "react";
import { Switch, Route, useRouteMatch, withRouter } from "react-router-dom";

// components
import AssignmentList from "./List";
import AssignmentDetail from "./Detail";
import { Toolbar } from "@material-ui/core";

const Assignments = () => {
  const match = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={`${match.path}/:slug`}>
          <AssignmentDetail />
        </Route>
        <Route path={`${match.path}`}>
          <AssignmentList />
        </Route>
      </Switch>
      <Toolbar />
    </>
  );
};

export default withRouter(Assignments);
