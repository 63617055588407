import React from "react";
import { Card, Typography, Box } from "@material-ui/core";

import ColorLinearProgress from "../../common/ColorLinearProgress";

const CompletionCard = ({ taskModules }) => {
  const requiredTasks = taskModules.filter(module => module.tasks[0].required);
  const completedRequiredTasks = requiredTasks.filter(
    module => module.tasks[0].studentTask.done
  );
  const completedTasks = taskModules.filter(
    module => module.tasks[0].studentTask.done
  );

  return (
    <Card>
      <Box mx={3} my={5} pr={3}>
        <Typography style={{ color: "#9A9AB0" }} className="mb-5">
          YOUR PROGRESS
        </Typography>
        <Box marginBottom={3}>
          Block Completion
          <Typography className="float-right" color="primary">
            {taskModules.length
              ? parseInt((completedTasks.length / taskModules.length) * 100)
              : 100}
            %
          </Typography>
        </Box>
        <ColorLinearProgress
          color="blue"
          value={
            taskModules.length
              ? parseInt((completedTasks.length / taskModules.length) * 100)
              : 100
          }
        />
        <Box marginBottom={3} marginTop={5}>
          Required Tasks Completion
          <Typography className="float-right" color="primary">
            {requiredTasks.length
              ? parseInt(
                  (completedRequiredTasks.length / requiredTasks.length) * 100
                )
              : 100}
            %
          </Typography>
        </Box>
        <ColorLinearProgress
          value={
            requiredTasks.length
              ? (completedRequiredTasks.length / requiredTasks.length) * 100
              : 100
          }
          color="blue"
        />
      </Box>
    </Card>
  );
};

export default CompletionCard;
