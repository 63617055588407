import React from "react";
import gql from "graphql-tag";
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/react-hooks";
import { useParams, Link } from "react-router-dom";

import {
  Grid,
  Paper,
  Box,
  Typography,
  Toolbar,
  Button
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import LinearLoading from "../../common/LinearLoading";
import Error from "../../common/Error";
import Blocks from "./Blocks";
import BlockContent from "../Blocks/BlockContent";

import Students from "./Students";

export const COHORT = gql`
  query($cohortId: Int, $blockId: Int, $moduleId: Int!) {
    cohort(cohortId: $cohortId) {
      id
      title
      bootcamp
      cohortBlocks {
        id
        isCompleted
        block {
          id
          title
        }
      }
      students {
        id
        name
        github
        studentTask(cohortId: $cohortId, moduleId: $moduleId) {
          id
          score
          done
          timeDone
          notes
          task {
            id
            required
            module {
              id
              assignment {
                id
                slug
                title
              }
            }
          }
        }
      }
    }

    module(moduleId: $moduleId) {
      id
      assignment {
        id
        title
        slug
        githubRepoName
        activeSolution
      }
    }

    cohortBlock(cohortId: $cohortId, blockId: $blockId) {
      id
      isCompleted
      percentages {
        done
        pushedIntime
      }
      block {
        id
        title
        modules {
          id
          moduleType
          isWorkshop
          assignment {
            id
            title
            slug
            githubRepoName
            activeSolution
          }
          chapter {
            title
            slug
            workshop {
              title
              slug
            }
          }
          tasks(cohortId: $cohortId) {
            id
            required
            timedOut
            isActive
          }
        }
      }
    }
  }
`;

const Assignment = () => {
  let { cohortId, blockId, moduleId } = useParams();
  const { loading, error, data } = useQuery(COHORT, {
    variables: { cohortId, blockId, moduleId },
    fetchPolicy: "cache-first"
  });

  if (loading) return <LinearLoading />;
  if (error) return <Error error={error} />;

  const { cohort, cohortBlock, module } = data;

  const doneStudents = cohort.students.filter(
    student => student.studentTask.done
  );
  const notDoneStudents = cohort.students.filter(
    student => !student.studentTask.done
  );

  const backLink = blockId
    ? `/x/${cohortId}/block/${blockId}`
    : cohortId
    ? `/x/${cohortId}`
    : "/x";

  return (
    <>
      <Helmet>
        <title>{module.assignment.title}|Warehouse</title>
      </Helmet>
      <Toolbar />
      <Link to={backLink}>
        <Button>
          <ArrowBackIosIcon fontSize="small" />
          Back
        </Button>
      </Link>
      <Grid container spacing={4}>
        <Grid item lg={6} xs={12}>
          <Box style={{ backgroundColor: "white" }} component={Paper} mt={1}>
            <Blocks cohortBlocks={cohort.cohortBlocks} />
            <BlockContent
              modules={cohortBlock.block.modules}
              percentages={cohortBlock.percentages}
            />
          </Box>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Box
            style={{ backgroundColor: "white" }}
            component={Paper}
            p={2}
            pt={3}
          >
            <Typography variant="h6">
              <span style={{ color: "#A4A4C2" }}>STUDENTS</span> |{" "}
              {module.assignment.title}
            </Typography>
            <Students
              doneStudents={doneStudents}
              notDoneStudents={notDoneStudents}
              module={module}
              cohortId={cohort.id}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Assignment;
