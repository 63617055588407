import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

export const ExpansionPanel = withStyles(theme => ({
  root: {
    borderRadius: "20px",
    backgroundColor: theme.palette.primary.lighter,
    boxShadow: "none",
    "&:last-child": {
      borderBottom: 0,
      borderRadius: "0px 0px 8px 8px"
    },
    "&:first-child": {
      borderRadius: "8px 8px 0px 0px"
    },

    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {},
  disabled: { backgroundColor: "transparent !important" }
}))(MuiExpansionPanel);

export const ExpansionPanelSummary = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.dark,
    borderBottom: "3px solid #EBEBF3",
    marginBottom: -1,

    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
      borderBottom: "1px solid #EBEBF3"
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
      color: "#2633A9"
    }
  },
  expanded: {}
}))(MuiExpansionPanelSummary);

export const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    borderLeft: "2px solid #2633A9"
  }
}))(MuiExpansionPanelDetails);

export const useStyles = makeStyles(theme => ({
  listItem: {
    backgroundColor: "#EEEEF8",
    borderBottom: "3px solid #EBEBF3",
    borderRadius: "8px 8px 0px 0px"
  }
}));
