import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Grid, Toolbar, Box, Paper, Typography } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import LinearLoading from "../../common/LinearLoading";
import Error from "../../common/Error";
import Students from "./Students/Students";
import Blocks from "../Blocks/Blocks";
import BlockContent from "../Blocks/BlockContent";
import gql from "graphql-tag";

export const COHORT = gql`
  query($cohortId: Int, $blockId: Int) {
    cohort(cohortId: $cohortId) {
      id
      title
      bootcamp
      cohortBlocks {
        id
        isCompleted
        block {
          id
          title
        }
      }
      students {
        id
        name
        github
        studentTasks(cohortId: $cohortId, blockId: $blockId) {
          id
          score
          done
          timeDone
          notes
          task {
            id
            required
            module {
              id
              assignment {
                id
                slug
                title
              }
            }
          }
        }
      }
    }

    cohortBlock(cohortId: $cohortId, blockId: $blockId) {
      id
      isCompleted
      percentages {
        done
        pushedIntime
      }
      block {
        id
        title
        modules {
          id
          moduleType
          isWorkshop
          assignment {
            id
            title
            slug
            githubRepoName
            activeSolution
          }
          chapter {
            title
            slug
            workshop {
              title
              slug
            }
          }
          tasks(cohortId: $cohortId) {
            id
            required
            timedOut
            isActive
          }
        }
      }
    }
  }
`;

const Cohort = () => {
  let { cohortId, blockId } = useParams();

  const { loading, error, data } = useQuery(COHORT, {
    variables: { cohortId, blockId }
  });

  const [selectedModules, setSelectedModules] = useState([]);

  if (loading) return <LinearLoading />;
  if (error) return <Error error={error} />;

  const { cohort, cohortBlock } = data;

  if (cohort.cohortBlocks.length === 0)
    return (
      <Typography color="primary" className="text-center mt-5" variant="h5">
        Cohort {cohort.title} is not setup yet
      </Typography>
    );

  return (
    <>
      <Helmet>
        <title>{cohort.title}|Warehouse</title>
      </Helmet>
      <Toolbar />
      <Grid container spacing={4}>
        <Grid item lg={6} xs={12}>
          <Box style={{ backgroundColor: "white" }} component={Paper}>
            <Blocks cohortBlocks={cohort.cohortBlocks} />
            <BlockContent
              modules={cohortBlock.block.modules}
              percentages={cohortBlock.percentages}
              selectedModules={selectedModules}
              setSelectedModules={setSelectedModules}
            />
          </Box>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Students
            students={cohort.students}
            selectedModules={selectedModules}
            cohortId={cohort.id}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Cohort;
