import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { STUDENT_COHORT } from "../../../graphql/Queries/Dashboard";
import { Grid, Typography } from "@material-ui/core";
import LinearLoading from "../../common/LinearLoading";
import Error from "../../common/Error";
import Blocks from "./Blocks";
import Curriculum from "../Curriculum";
import CompletionCard from "./CompletionCard";
import RequiredCard from "./RequiredCard";
import { MODULE_TYPE } from "../../../constants";

const Cohort = ({ selectedCohort }) => {
  let { block } = useParams();
  const { loading, error, data } = useQuery(STUDENT_COHORT, {
    variables: { cohortId: selectedCohort }
  });
  if (loading) return <LinearLoading />;
  if (error) return <Error error={error} />;

  const cohort = data.studentCohort;

  if (cohort.cohortBlocks.length === 0)
    return (
      <Typography color="primary" className="text-center mt-5" variant="h5">
        Cohort {cohort.title} is not setup yet
      </Typography>
    );

  if (!block) block = 0;

  return (
    <>
      <Blocks blocks={cohort.cohortBlocks} selectedBlock={block} />
      <Grid container spacing={6} className="mt-sm-4">
        <Grid item xs={12} md={8} className="mt-lg-n3">
          <Curriculum modules={cohort.cohortBlocks[block].block.modules} />
        </Grid>
        <Grid item xs={12} md={4} className="mt-lg-5">
          <CompletionCard
            taskModules={cohort.cohortBlocks[block].block.modules.filter(
              module => module.moduleType === MODULE_TYPE.TASK
            )}
          />
          <RequiredCard
            taskModules={cohort.cohortBlocks[block].block.modules.filter(
              module => module.moduleType === MODULE_TYPE.TASK
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Cohort;
