import React, { useState } from "react";

import { Box, Popover, TextField, Button, IconButton } from "@material-ui/core";
import CommentOutlinedIcon from "@material-ui/icons/CommentOutlined";

const Note = ({ studentTask, updateStudentTask }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const saveNotes = async e => {
    e.preventDefault();
    const data = new FormData(e.target);
    let notes = data.get("notes").trim();
    try {
      await updateStudentTask({
        variables: { id: studentTask.id, notes: notes }
      });
      setAnchorEl(null);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <IconButton
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
        color={studentTask.notes ? "primary" : "default"}
      >
        <CommentOutlinedIcon fontSize="small" />
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left"
        }}
      >
        <Box m={2}>
          <form onSubmit={saveNotes}>
            <TextField
              name="notes"
              variant="outlined"
              multiline
              rowsMax={4}
              defaultValue={studentTask.notes}
            />
            <br />
            <Button
              color="primary"
              variant="contained"
              type="submit"
              size="small"
              className="my-1"
            >
              Save
            </Button>
          </form>
        </Box>
      </Popover>
    </>
  );
};

export default Note;
