import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import {
  Card,
  CardActionArea,
  CardMedia,
  Typography,
  CardContent,
  Grid
} from "@material-ui/core";

import TopicCard from "../../common/TopicCard";

const truncate = str => {
  const maxLength = 105;
  return str.length <= maxLength ? str : str.substring(0, maxLength) + "...";
};

const WorkshopCard = ({ workshop }) => {
  const match = useRouteMatch();
  const firstChapter = workshop.firstChapter.slug;
  const firstSection = workshop.firstChapter.firstSection?.slug ?? null;
  const topicCards = workshop.topics.map(topic => (
    <TopicCard key={topic.title} text={topic.title} />
  ));
  if (firstSection === null) console.log(workshop.title);
  return (
    <Grid item lg={3} md={4} sm={6} xs={12}>
      <Link
        to={
          firstSection
            ? `${match.path}/${workshop.slug}/${firstChapter}/${firstSection}`
            : `${match.path}`
        }
      >
        <Card className="h-100">
          <CardActionArea>
            <CardMedia
              component="img"
              image={workshop.image}
              style={{
                height: "170px",
                objectFit: "contain"
              }}
            />
            <CardContent>
              <Typography gutterBottom variant="h6">
                {workshop.title}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {truncate(workshop.description)}
              </Typography>
            </CardContent>
            <CardContent>{topicCards}</CardContent>
          </CardActionArea>
        </Card>
      </Link>
    </Grid>
  );
};

export default WorkshopCard;
