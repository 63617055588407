import React, { useState } from "react";
import { useLocation, useHistory, useRouteMatch } from "react-router-dom";
import { Grid } from "@material-ui/core";

import WorkshopCard from "./WorkshopCard";
import Filter from "./Filter";

const List = ({ workshops, topics }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const topic = params.get("topic");
  const [selectedTopic, setSelectedTopic] = useState(topic ? topic : "");

  const filteredWorkshops =
    selectedTopic === ""
      ? workshops
      : workshops.filter(workshop =>
          workshop.topics.some(topic => topic.title === selectedTopic)
        );

  const workshopCards = filteredWorkshops.map(workshop => (
    <WorkshopCard workshop={workshop} key={workshop.slug} />
  ));

  const handleTopicSelection = topic => {
    setSelectedTopic(topic);
    history.push(`${match.path}?topic=${topic}`);
  };

  return (
    <>
      <Filter
        topics={topics}
        selectedTopic={selectedTopic}
        handleSelection={handleTopicSelection}
      />

      <Grid container spacing={3}>
        {workshopCards}
      </Grid>
    </>
  );
};

export default List;
