import gql from "graphql-tag";

export const REGISTER = gql`
  mutation(
    $email: String!
    $password1: String!
    $password2: String!
    $github: String
    $firstName: String!
    $lastName: String!
  ) {
    register(
      email: $email
      username: $email
      firstName: $firstName
      lastName: $lastName
      password1: $password1
      password2: $password2
      github: $github
    ) {
      success
      errors
      token
    }
  }
`;

export const LOGIN = gql`
  mutation($email: String!, $password: String!) {
    tokenAuth(username: $email, password: $password) {
      token
      success
      errors
      user {
        id
        username
        isStaff
      }
    }
  }
`;

export const EMAIL_RESET_PASSWORD = gql`
  mutation($email: String!) {
    sendPasswordResetEmail(email: $email) {
      success
      errors
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation($token: String!, $newPassword1: String!, $newPassword2: String!) {
    passwordReset(
      token: $token
      newPassword1: $newPassword1
      newPassword2: $newPassword2
    ) {
      success
      errors
    }
  }
`;
