import React from "react";
import ReactMarkdown from "react-markdown";
import CodeBlock from "../../common/CodeBlock";

function Image(props) {
  return <img {...props} style={{ maxWidth: "100%" }} alt="section" />;
}

const Content = ({ section }) => {
  return (
    <>
      <h1>{section.title}</h1>
      <ReactMarkdown
        source={section.content}
        renderers={{ code: CodeBlock, image: Image }}
      />
    </>
  );
};

export default Content;
