import React from "react";
import { Table, TableBody, TableContainer } from "@material-ui/core";
import ModuleRow from "./ModuleRow";

const ModuleTable = ({
  filteredModules,
  filter,
  setSelectedModules,
  selectedModules
}) => {
  const addModule = id => {
    let modules = [...selectedModules];

    modules.push(id);
    setSelectedModules(modules);
  };
  const removeModule = id => {
    let modules = [...selectedModules];
    modules.splice(
      modules.findIndex(module => module === id),
      1
    );
    setSelectedModules(modules);
  };
  const moduleRows = filteredModules.map((module, index) => (
    <ModuleRow
      module={module}
      index={index}
      filter={filter}
      key={index}
      addModule={addModule}
      removeModule={removeModule}
      isSelected={
        selectedModules &&
        selectedModules.some(selectedModule => selectedModule === module.id)
      }
    />
  ));
  return (
    <TableContainer style={{ backgroundColor: "#F7F7FF" }} className="mb-4">
      <div className="scroll-table-y">
        <Table stickyHeader>
          <TableBody>{moduleRows}</TableBody>
        </Table>
      </div>
    </TableContainer>
  );
};

export default ModuleTable;
