import React from "react";
import { useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import withWidth from "@material-ui/core/withWidth";
import { TableRow, Typography, Grid } from "@material-ui/core";

import TaskStatus from "./TaskStatus";
import ColorLinearProgress from "../../common/ColorLinearProgress";

const CustomTableRow = withStyles({
  root: {
    color: "red !important",
    "&:hover": {
      backgroundColor: "#F8F8FE !important"
    }
  }
})(TableRow);

const TableCell = withStyles({
  root: {
    borderBottom: "none"
  }
})(MuiTableCell);

const ModuleRow = ({ module, index, width }) => {
  let history = useHistory();
  const task = module.moduleType && module.tasks && module.tasks[0];
  const link = task
    ? `/assignments/${module.assignment.slug}`
    : module.isWorkshop
    ? `/workshops/${module.chapter.workshop.slug}/`
    : `/workshops/${module.chapter.workshop.slug}/${module.chapter.slug}`;

  const title = task
    ? module.assignment.title
    : module.isWorkshop
    ? module.chapter.workshop.title
    : module.chapter.title;

  const icon = task ? <TaskStatus task={task} /> : null;

  const progress = task ? (
    <Grid container spacing={1}>
      <Grid item xs={7} className="my-auto ml-auto">
        <ColorLinearProgress value={task.studentTask.score} color="blue" />
      </Grid>
      <Grid item xs={5}>
        <Typography variant="body2" style={{ color: "#5A5A7E" }}>
          {task.studentTask.score}%
        </Typography>
      </Grid>
    </Grid>
  ) : null;

  return (
    <CustomTableRow
      hover
      onClick={() => history.push(link)}
      className="pointer"
      key={index}
    >
      <TableCell style={{ color: "#767698", width: "5%" }}>
        {index + 1}
      </TableCell>
      <TableCell>{title}</TableCell>
      <TableCell>
        {icon}
        {width === "xs" ? <div className="mt-2"> {progress} </div> : null}
      </TableCell>
      {width === "xs" ? null : (
        <TableCell className="w-25">{progress}</TableCell>
      )}
    </CustomTableRow>
  );
};

export default withWidth()(ModuleRow);
