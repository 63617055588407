import gql from "graphql-tag";

export const UPDATE_TASK = gql`
  mutation(
    $id: Int!
    $required: Boolean
    $timedOut: Boolean
    $isActive: Boolean
  ) {
    updateTask(
      id: $id
      required: $required
      timedOut: $timedOut
      isActive: $isActive
    ) {
      task {
        id
        required
        timedOut
        isActive
      }
    }
  }
`;

export const UPDATE_STUDENT_TASK = gql`
  mutation($id: Int!, $notes: String, $score: Int, $done: Boolean) {
    updateStudentTask(id: $id, notes: $notes, score: $score, done: $done) {
      studentTask {
        id
        score
        done
        timeDone
        notes
      }
    }
  }
`;
