import React, { useState } from "react";
import { Table, TableBody, TableRow, Box, TableCell } from "@material-ui/core";

import { Button } from "../Styles";
import StudentRow from "./StudentRow";

const Students = ({ doneStudents, notDoneStudents, module, cohortId }) => {
  const [done, setDone] = useState(false);
  const doneStudentRows = doneStudents.map((student, index) => (
    <StudentRow
      student={student}
      module={module}
      cohortId={cohortId}
      key={index}
    />
  ));
  const notDoneStudentRows = notDoneStudents.map((student, index) => (
    <StudentRow
      student={student}
      module={module}
      cohortId={cohortId}
      key={index}
    />
  ));

  return (
    <>
      <Box p={1} mt={3} style={{ backgroundColor: "#F7F7FF" }}>
        <Button
          className="mx-1"
          color="primary"
          variant={done ? "contained" : "text"}
          onClick={() => setDone(true)}
        >
          Done
        </Button>
        <Button
          className="mx-1"
          color="primary"
          variant={!done ? "contained" : "text"}
          onClick={() => setDone(false)}
        >
          Not Done
        </Button>
      </Box>
      <div className="scroll-table-y">
        <Table style={{ backgroundColor: "#F7F7FF" }}>
          <TableBody>
            {(done && doneStudentRows.length === 0) ||
            (!done && notDoneStudentRows.length === 0) ? (
              <TableRow>
                <TableCell>No students</TableCell>
              </TableRow>
            ) : done ? (
              doneStudentRows
            ) : (
              notDoneStudentRows
            )}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default Students;
