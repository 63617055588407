import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { LinearProgress } from "@material-ui/core";

const BlueLinearProgress = withStyles(theme => ({
  root: {
    height: 6,
    borderRadius: 15
  },
  colorPrimary: {
    backgroundColor: "#F1F1FB"
  },
  bar: {
    borderRadius: 15,
    backgroundColor: "#2633A"
  }
}))(LinearProgress);

const GreenLinearProgress = withStyles(theme => ({
  root: {
    height: 6,
    borderRadius: 15
  },
  colorPrimary: {
    backgroundColor: "#EEF9EB"
  },
  bar: {
    borderRadius: 15,
    backgroundColor: "#63C24A"
  }
}))(LinearProgress);

const YellowLinearProgress = withStyles(theme => ({
  root: {
    height: 6,
    borderRadius: 15
  },
  colorPrimary: {
    backgroundColor: "#FEF8E7"
  },
  bar: {
    borderRadius: 15,
    backgroundColor: "#EFB230"
  }
}))(LinearProgress);

const ColorLinearProgress = ({ color, value }) => {
  if (color === "blue")
    return <BlueLinearProgress variant="determinate" value={value} />;
  if (color === "green")
    return <GreenLinearProgress variant="determinate" value={value} />;
  if (color === "yellow")
    return <YellowLinearProgress variant="determinate" value={value} />;
  return <LinearProgress />;
};

export default ColorLinearProgress;
