import { makeStyles } from "@material-ui/core/styles";

export const useColorStyles = makeStyles(theme => ({
  primaryLight: { color: theme.palette.primary.light },
  primaryDark: { color: theme.palette.primary.dark },
  primary: { color: theme.palette.primary.main }
}));

export const useBgColorStyles = makeStyles(theme => ({
  primaryLight: { backgroundColor: theme.palette.primary.light },
  primaryDark: { backgroundColor: theme.palette.primary.dark },
  primary: { backgroundColor: theme.palette.primary.main },
  secondary: { backgroundColor: `${theme.palette.secondary.main} !important` },
  secondaryLight: { backgroundColor: theme.palette.secondary.light },
  secondaryDark: { backgroundColor: theme.palette.secondary.dark }
}));
