import React from "react";
import { Box, Typography, Button, Card } from "@material-ui/core";

import { api } from "../../../api";

const StudentInfo = ({ student }) => {
  return (
    <Card>
      <Box mx={3} my={2} pr={3}>
        <Typography color="primary" variant="h5" className="mb-3">
          {student.name}
        </Typography>
        <Typography>Admin Page: </Typography>
        <Button
          target="_blank"
          size="small"
          href={`${api}/admin/users/user/${student.id}/change/`}
        >
          {student.name}
        </Button>

        <Typography>Github: </Typography>
        <Button
          target="_blank"
          size="small"
          href={`https://www.github.com/${student.github}/`}
        >
          {student.github}
        </Button>
      </Box>
    </Card>
  );
};

export default StudentInfo;
