import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    borderRadius: "5px"
  }
}));

const TopicCard = ({ text }) => {
  const classes = useStyles();
  return (
    <div className={`mb-1 mt-1 px-2 py-2 small mx-1 d-inline ${classes.root}`}>
      {text}
    </div>
  );
};

export default TopicCard;
