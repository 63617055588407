import gql from "graphql-tag";

export const USER = gql`
  query {
    me {
      id
      username
      isStaff
    }
  }
`;
