import React from "react";
import "./App.css";
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { Toolbar } from "@material-ui/core";
import { useApolloClient, useQuery } from "@apollo/react-hooks";
import theme from "./ui/theme";
import { USER } from "./graphql/Queries/Authentication";

import {
  checkForToken,
  isAuthenticated
} from "./graphql/helpers/Authentication";

import Navbar from "./components/Navbar";
import Workshops from "./components/Workshops";
import Assignments from "./components/Assignments";
import Dashboard from "./components/Dashboard";
import SecretDashboard from "./components/SecretDashboard";
import Authentication from "./components/Authentication";
import Loading from "./components/common/Loading";
import Error from "./components/common/Error";
import Footer from "./components/common/Footer";

function App() {
  checkForToken(useApolloClient());
  const { data, loading, error } = useQuery(USER);
  const location = useLocation();

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  const user = data.me;

  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <Toolbar />
      <Switch>
        <Route
          path="/workshops"
          render={props =>
            isAuthenticated() ? (
              <Workshops {...props} />
            ) : (
              <Redirect to={`/auth/login?redirect=${location.pathname}`} />
            )
          }
        />
        <Route
          path="/assignments"
          render={props =>
            isAuthenticated() ? (
              <Assignments {...props} />
            ) : (
              <Redirect to={`/auth/login?redirect=${location.pathname}`} />
            )
          }
        />
        <Route
          path={["/dashboard/block/:block", "/dashboard"]}
          render={props =>
            isAuthenticated() ? (
              <Dashboard {...props} />
            ) : (
              <Redirect to={`/auth/login?redirect=${location.pathname}`} />
            )
          }
        />
        <Route
          path="/x"
          render={props =>
            isAuthenticated() && user.isStaff ? (
              <SecretDashboard {...props} />
            ) : (
              <Redirect to={`/auth/login?redirect=${location.pathname}`} />
            )
          }
        />
        <Route path="/auth">
          <Authentication />
        </Route>
        <Redirect to="/workshops" />
      </Switch>

      <Footer />
    </ThemeProvider>
  );
}

export default withRouter(App);
