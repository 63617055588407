import { withStyles } from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableRow from "@material-ui/core/TableRow";
import MuiButton from "@material-ui/core/Button";
import { IconButton } from "@material-ui/core";

// Tables
export const TableCell = withStyles({
  root: {
    borderBottomColor: "#ECECFF"
  }
})(MuiTableCell);

export const TableRow = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "#E9E9FB !important"
    }
  }
})(MuiTableRow);

//   Buttons
export const WarningButton = withStyles({
  root: {
    color: "#CBCFF1",
    backgroundColor: "white",
    padding: "7px",
    marginRight: "10px"
  },
  colorPrimary: { color: "#FFB71F", backgroundColor: "#FFF9ED" }
})(IconButton);

export const VisibleButton = withStyles({
  root: {
    color: "#CBCFF1",
    backgroundColor: "white",
    padding: "7px",
    marginRight: "10px"
  },
  colorPrimary: { color: "#2EAF22", backgroundColor: "#EEFFEE" }
})(IconButton);

export const TimeOutButton = withStyles({
  root: {
    color: "#CBCFF1",
    backgroundColor: "white",
    padding: "7px",
    marginRight: "10px"
  },
  colorPrimary: { color: "#E43F3F", backgroundColor: "#FFF7F5" }
})(IconButton);

export const ActiveButton = withStyles({
  root: {
    color: "#CBCFF1",
    backgroundColor: "white",
    padding: "7px",
    marginRight: "10px"
  },
  colorPrimary: { color: "#154360", backgroundColor: "#EBF5FB" }
})(IconButton);

export const Button = withStyles(theme => ({
  textPrimary: { color: theme.palette.secondary.contrastText },
  containedPrimary: {
    backgroundColor: "white",
    color: theme.palette.primary.main,
    "&:hover": {
      color: "white"
    }
  }
}))(MuiButton);
