import React from "react";
import { Button, Box } from "@material-ui/core";
import { useBgColorStyles } from "../../../ui/theme/colors";

const Filter = ({ topics, selectedTopic, handleSelection }) => {
  const bgColor = useBgColorStyles();

  const topicButtons = topics.map(topic => (
    <Button
      key={topic.title}
      className="mx-1 text-nowrap"
      color="primary"
      variant={
        selectedTopic.toLowerCase() === topic.title.toLowerCase()
          ? "contained"
          : "text"
      }
      onClick={() => handleSelection(topic.title)}
    >
      {topic.title}
    </Button>
  ));

  return (
    <div className="scrolling-wrapper">
      <Box m={3} p={1} className={`rounded w-fit-content ${bgColor.secondary}`}>
        {topicButtons}
      </Box>
    </div>
  );
};

export default Filter;
