import { createMuiTheme } from "@material-ui/core/styles";

const palette = {
  secondary: {
    main: "#FAFAFF",
    light: "#FFFFFF",
    contrastText: "#5A5A7E",
    dark: "#F8F8FD"
  },
  primary: {
    main: "#2633A9",
    light: "#F1F1F8",
    dark: "#3A3A5A",
    contrastText: "#ffffff"
  },
  primarydark: {
    main: "#3A3A5A",
    contrastText: "#ffffff"
  }
};
const themeName = "White Governor Bay Lion";

export default createMuiTheme({ palette, themeName });
