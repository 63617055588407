import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import {
  TextField,
  Button,
  Box,
  Paper,
  Container,
  Toolbar,
  Link
} from "@material-ui/core";

import { EMAIL_RESET_PASSWORD } from "../../../graphql/mutations/Authentication";
import ErrorMessage from "../../common/ErrorMessage";

const ResetPasswordEmail = () => {
  let history = useHistory();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [resetPassword] = useMutation(EMAIL_RESET_PASSWORD, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      const auth = data.sendPasswordResetEmail;
      if (auth.success) {
        setSuccess(true);
      } else {
        setError(auth.errors);
      }
    }
  });

  const handleSubmit = async e => {
    e.preventDefault();
    const data = new FormData(e.target);
    const input = {
      email: data.get("email")
    };
    resetPassword({ variables: input });
  };

  return (
    <Container maxWidth="sm">
      <Helmet>
        <title>Forgot Password|Warehouse</title>
      </Helmet>
      <Toolbar />
      <Box px={4} py={2} component={Paper} variant="outlined">
        <form onSubmit={handleSubmit}>
          <TextField
            name="email"
            label="email"
            variant="outlined"
            className="my-2"
            size="small"
            required
            fullWidth
          />
          {success && <p>Email sent successfully</p>}
          {error && <ErrorMessage messages={error.email} />}
          {error && <ErrorMessage messages={error.nonFieldErrors} />}
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className="my-2"
            fullWidth
          >
            Send email
          </Button>
          <Link
            component="button"
            variant="body2"
            className="w-100"
            onClick={() => history.push("/auth/register")}
          >
            Signup for an Account
          </Link>
          <Link
            component="button"
            variant="body2"
            className="w-100"
            onClick={() => history.push("/auth/login")}
          >
            Have an Account?
          </Link>
        </form>
      </Box>
    </Container>
  );
};

export default ResetPasswordEmail;
