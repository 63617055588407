import React from "react";
import { useQuery } from "@apollo/react-hooks";
import {
  Switch,
  Route,
  useRouteMatch,
  withRouter,
  useHistory
} from "react-router-dom";
import {
  Container,
  TextField,
  MenuItem,
  Typography,
  Toolbar
} from "@material-ui/core";
import { COHORTS } from "../../graphql/Queries/Dashboard";

import Loading from "../common/Loading";
import Error from "../common/Error";
import Cohort from "./Cohort";
import Assignment from "./Assignment";
import Student from "./Student";

const SecretDashboard = () => {
  const { data, loading, error } = useQuery(COHORTS);
  const match = useRouteMatch();
  const history = useHistory();

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  const cohorts = data.cohorts;

  if (cohorts.length === 0)
    return (
      <Typography color="primary" className="text-center mt-5" variant="h5">
        All cohorts are currently archived
      </Typography>
    );

  return (
    <div style={{ backgroundColor: "#F7F7FF", minHeight: "100vh" }}>
      <Container>
        <Typography
          variant="overline"
          className="d-block text-muted pt-2 pt-sm-4"
        >
          BOOTCAMP
        </Typography>
        <TextField
          className="bg-white"
          size="small"
          select
          variant="outlined"
          defaultValue={0}
        >
          {cohorts.map((cohort, index) => (
            <MenuItem
              key={cohort.id}
              value={index}
              onClick={() => history.push(`${match.path}/${cohort.id}`)}
            >
              {cohort.bootcamp}-{cohort.title}
            </MenuItem>
          ))}
        </TextField>
        <Switch>
          <Route
            exact
            path={[
              `${match.path}/:cohortId/block/:blockId`,
              `${match.path}/:cohortId`,
              `${match.path}/block/:blockId`,
              `${match.path}`
            ]}
          >
            <Cohort />
          </Route>
          <Route
            path={[
              `${match.path}/:cohortId/block/:blockId/module/:moduleId`,
              `${match.path}/:cohortId/module/:moduleId`,
              `${match.path}/module/:moduleId`
            ]}
          >
            <Assignment />
          </Route>
          <Route
            path={[
              `${match.path}/:cohortId/block/:blockId/student/:studentId`,
              `${match.path}/:cohortId/student/:studentId`
            ]}
          >
            <Student />
          </Route>
        </Switch>
      </Container>
      <Toolbar />
    </div>
  );
};

export default withRouter(SecretDashboard);
