import React from "react";
import { Link } from "react-router-dom";
import { Typography, List, ListItem, Toolbar } from "@material-ui/core";

const References = ({ references }) => {
  if (references.length === 0) return null;
  const refrenceLinks = references.map((reference, index) => (
    <Link
      key={index}
      to={`/workshops/${reference.chapter.workshop.slug}/${reference.chapter.slug}/${reference.slug}`}
    >
      <ListItem button>
        <Typography color="primary" variant="subtitle1" display="block">
          {reference.chapter.workshop.title} / {reference.chapter.title} /{" "}
          {reference.title}
        </Typography>
      </ListItem>
    </Link>
  ));
  return (
    <>
      <Toolbar />
      <h2>
        <b>References</b>
      </h2>
      <List component="nav" aria-label="main mailbox folders">
        {refrenceLinks}
      </List>
    </>
  );
};

export default References;
