import React from "react";
import { MODULE_TYPE } from "../../../constants";
import { Button, Box, Toolbar } from "@material-ui/core";

const TypeFilters = ({ moduleType, setModuleType }) => {
  return (
    <Toolbar className="p-0">
      <Box
        mb={2}
        p={1}
        className={`rounded bg-white`}
        style={{ width: "fit-content" }}
      >
        <Button
          className="mx-1"
          color="primary"
          variant={moduleType === "" ? "contained" : "text"}
          onClick={() => setModuleType("")}
        >
          All
        </Button>
        <Button
          className="mx-1"
          color="primary"
          variant={moduleType === MODULE_TYPE.TASK ? "contained" : "text"}
          onClick={() => setModuleType(MODULE_TYPE.TASK)}
        >
          Tasks
        </Button>
        <Button
          className="mx-1"
          color="primary"
          variant={moduleType === MODULE_TYPE.WORKSHOP ? "contained" : "text"}
          onClick={() => setModuleType(MODULE_TYPE.WORKSHOP)}
        >
          Workshops
        </Button>
      </Box>
    </Toolbar>
  );
};

export default TypeFilters;
