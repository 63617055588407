import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation } from "@apollo/react-hooks";
import { useHistory, useParams } from "react-router-dom";
import {
  TextField,
  Button,
  Box,
  Paper,
  Container,
  Toolbar,
  Link
} from "@material-ui/core";

import { RESET_PASSWORD } from "../../../graphql/mutations/Authentication";
import ErrorMessage from "../../common/ErrorMessage";

const ResetPassword = () => {
  let { token } = useParams();
  let history = useHistory();
  const [error, setError] = useState(null);

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      const auth = data.passwordReset;
      if (auth.success) {
        history.push("/auth/login");
      } else {
        setError(auth.errors);
      }
    }
  });

  const handleSubmit = async e => {
    e.preventDefault();
    const data = new FormData(e.target);
    const input = {
      newPassword1: data.get("newPassword1"),
      newPassword2: data.get("newPassword2"),
      token: token
    };
    resetPassword({ variables: input });
  };

  return (
    <Container maxWidth="sm">
      <Helmet>
        <title>Reset Password|Warehouse</title>
      </Helmet>
      <Toolbar />
      <Box px={4} py={2} component={Paper} variant="outlined">
        <form onSubmit={handleSubmit}>
          <TextField
            name="newPassword1"
            label="New Password"
            type="password"
            variant="outlined"
            className="my-2"
            size="small"
            required
            fullWidth
          />
          <TextField
            name="newPassword2"
            label="Retype Password"
            type="password"
            variant="outlined"
            className="my-2"
            size="small"
            required
            fullWidth
          />
          {error && <ErrorMessage messages={error.newPassword2} />}
          {error && <ErrorMessage messages={error.nonFieldErrors} />}
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className="my-2"
            fullWidth
          >
            Reset Password
          </Button>
          <Link
            component="button"
            variant="body2"
            className="w-100"
            onClick={() => history.push("/auth/register")}
          >
            Signup for an Account
          </Link>
          <Link
            component="button"
            variant="body2"
            className="w-100"
            onClick={() => history.push("/auth/login")}
          >
            Have an account?
          </Link>
        </form>
      </Box>
    </Container>
  );
};

export default ResetPassword;
