import React from "react";
import { Typography, Box, Container, Grid } from "@material-ui/core";
import { useColorStyles } from "../../../ui/theme/colors";

import AssignmentCard from "./AssignmentCard";

const AssignmentType = ({ assignmentType, bg, selectedTopic }) => {
  const colorClasses = useColorStyles();

  const filteredAssignments =
    selectedTopic === ""
      ? assignmentType.assignments
      : assignmentType.assignments.filter(assignment =>
          assignment.topics.some(topic => topic.title === selectedTopic)
        );

  const assignmentCards = filteredAssignments.map(assignment => (
    <AssignmentCard assignment={assignment} key={assignment.slug} />
  ));

  return (
    <Box
      component="div"
      p={5}
      style={{ backgroundColor: bg }}
      className="text-left"
    >
      <Container>
        <Typography
          variant="h6"
          component="span"
          className={`poppins text-capitalize ${colorClasses.primaryDark}`}
        >
          {assignmentType.title}
        </Typography>
        <span className="small text-muted ml-3">
          {filteredAssignments.length} Tasks
        </span>
        <Grid container spacing={3} className="mt-3">
          {assignmentCards}
        </Grid>
      </Container>
    </Box>
  );
};

export default AssignmentType;
