import React from "react";
import ReactMarkdown from "react-markdown";
import CodeBlock from "../../common/CodeBlock";
import { Toolbar } from "@material-ui/core";
import { useApolloClient } from "@apollo/react-hooks";
import { isStaff } from "../../../graphql/helpers/Authentication";

const Solution = ({ solution, active }) => {
  const client = useApolloClient();
  if (!(active || isStaff(client))) return null;
  return (
    <>
      <Toolbar />
      <h2>
        <b>Solution</b>
      </h2>
      <ReactMarkdown source={solution} renderers={{ code: CodeBlock }} />
    </>
  );
};

export default Solution;
