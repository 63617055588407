import jwt_decode from "jwt-decode";
import { USER } from "../Queries/Authentication";

export const setUser = (token, client) => {
  if (token) {
    localStorage.setItem("token", token);
  } else {
    localStorage.removeItem("token");
    client.writeData({
      data: { user: null }
    });
  }
};

export const checkForToken = async client => {
  const token = localStorage.getItem("token");
  if (token) {
    const currentTime = Date.now() / 1000;
    const user = jwt_decode(token);
    if (user.exp >= currentTime) {
      setUser(token, client);
    } else {
      setUser(null, client);
    }
  }
};

export const isAuthenticated = () => {
  return localStorage.getItem("token");
};

export const logout = client => {
  setUser(null, client);
};

export const isStaff = client => {
  const { me } = client.readQuery({ query: USER });
  return me && me.isStaff;
};
