import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Link, useHistory } from "react-router-dom";
import GitHubIcon from "@material-ui/icons/GitHub";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {
  TableRow,
  TableCell,
  Box,
  Typography,
  IconButton,
  Collapse,
  Table,
  Hidden
} from "@material-ui/core";

import { UPDATE_STUDENT_TASK } from "../../../../graphql/mutations/Dashboard";
import ColorLinearProgress from "../../../common/ColorLinearProgress";
import Note from "../../common/Note";

const StudentRow = ({ student, cohortId }) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [updateStudentTask] = useMutation(UPDATE_STUDENT_TASK);

  const requiredTasks = student.studentTasks.filter(
    studentTask => studentTask.task.required
  );

  const incompleteTasks = requiredTasks.filter(
    studentTask => !studentTask.done
  );

  const completionPercentage =
    requiredTasks.length !== 0
      ? (requiredTasks.length - incompleteTasks.length) / requiredTasks.length
      : 1;

  return (
    <>
      <TableRow
        className="pointer"
        hover
        onClick={() => history.push(`/x/${cohortId}/student/${student.id}`)}
      >
        <TableCell align="left">{student.name}</TableCell>
        <TableCell>
          <GitHubIcon className="mr-2" fontSize="small" />
          {student.github}
        </TableCell>
        <TableCell align="right">
          <Box display="flex" alignItems="center">
            <Hidden xsDown>
              <Box width="100%" mr={1}>
                <ColorLinearProgress
                  value={completionPercentage * 100}
                  color="blue"
                />
              </Box>
            </Hidden>

            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">
                {parseInt(completionPercentage * 100)}%
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell padding="checkbox">
          {incompleteTasks.length ? (
            <IconButton
              size="small"
              onClick={e => {
                e.stopPropagation();
                setOpen(!open);
              }}
            >
              <MoreHorizIcon color="error" />
            </IconButton>
          ) : null}
        </TableCell>
      </TableRow>
      {incompleteTasks.length ? (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography style={{ color: "#9A9AB0" }} className="mb-1">
                  INCOMPLETE TASKS
                </Typography>
                <Table size="small">
                  {incompleteTasks.map(studentTask => (
                    <TableRow key={studentTask.id} hover>
                      <TableCell>
                        <Link
                          to={`/assignments/${studentTask.task.module.assignment.slug}`}
                        >
                          <Typography
                            variant="body2"
                            gutterBottom
                            color="primary"
                          >
                            {studentTask.task.module.assignment.title}
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          href={`https://www.github.com/${student.github}/${studentTask.task.module.assignment.githubRepo}`}
                          target="_blank"
                          size="small"
                        >
                          <GitHubIcon fontSize="small" />
                        </IconButton>
                        <Note
                          studentTask={studentTask}
                          updateStudentTask={updateStudentTask}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
};

export default StudentRow;
