import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  LinearProgress,
  Link,
  Tooltip
} from "@material-ui/core";

import { TableCell, TableRow } from "../Styles";
import { UPDATE_STUDENT_TASK } from "../../../graphql/mutations/Dashboard";
import Note from "../common/Note";
import TaskStatus from "../common/TaskStatus";
import ManualScoring from "./ManualScoring";

const StudentRow = ({ student, module, cohortId }) => {
  const history = useHistory();

  const [updateStudentTask] = useMutation(UPDATE_STUDENT_TASK);

  const studentTask = student.studentTask;

  return (
    <TableRow hover>
      <TableCell>
        <Link
          className="pointer"
          onClick={() => history.push(`/x/${cohortId}/student/${student.id}`)}
        >
          {student.name}
        </Link>
      </TableCell>
      {studentTask.done ? (
        <TableCell>
          <Tooltip title={studentTask.timeDone}>
            <TaskStatus studentTask={studentTask} />
          </Tooltip>
        </TableCell>
      ) : null}

      <TableCell align="right">
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearProgress variant="determinate" value={studentTask.score} />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">
              {parseInt(studentTask.score)}%
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell padding="checkbox">
        <Note studentTask={studentTask} updateStudentTask={updateStudentTask} />
      </TableCell>
      <TableCell padding="checkbox">
        <ManualScoring
          updateStudentTask={updateStudentTask}
          studentTask={studentTask}
        />
      </TableCell>
    </TableRow>
  );
};

export default StudentRow;
