import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Checkbox } from "@material-ui/core";
import AlarmIcon from "@material-ui/icons/Alarm";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import AccessibilityIcon from "@material-ui/icons/Accessibility";

import {
  TableCell,
  TableRow,
  WarningButton,
  VisibleButton,
  TimeOutButton,
  ActiveButton
} from "../Styles";
import { MODULE_TYPE } from "../../../constants";
import { UPDATE_ASSIGNMENT } from "../../../graphql/mutations/Assignments";
import { UPDATE_TASK } from "../../../graphql/mutations/Dashboard";

const ModuleRow = ({ module, index, filter, addModule, removeModule }) => {
  const history = useHistory();
  const { cohortId, blockId } = useParams();

  const [updateAssignment] = useMutation(UPDATE_ASSIGNMENT);
  const [updateTask] = useMutation(UPDATE_TASK);

  const handleCheckboxClick = e => {
    e.stopPropagation();
    if (e.target.checked) addModule(module.id);
    else {
      removeModule(module.id);
    }
  };

  const isTask = module.moduleType === MODULE_TYPE.TASK;
  const title = isTask
    ? module.assignment.title
    : module.isWorkshop
    ? module.chapter.workshop.title
    : module.chapter.title;

  const actions = isTask ? (
    <>
      <WarningButton
        aria-label="required"
        color={module.tasks[0].required ? "primary" : "default"}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          updateTask({
            variables: {
              id: module.tasks[0].id,
              required: true
            }
          });
        }}
      >
        <ReportProblemOutlinedIcon fontSize="small" />
      </WarningButton>
      <VisibleButton
        aria-label="solution"
        color={module.assignment.activeSolution ? "primary" : "default"}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          updateAssignment({
            variables: {
              assignmentSlug: module.assignment.slug,
              activeSolution: true
            }
          });
        }}
      >
        <VisibilityOutlinedIcon fontSize="small" />
      </VisibleButton>
      <TimeOutButton
        aria-label="time out"
        color={module.tasks[0].timedOut ? "primary" : "default"}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          updateTask({
            variables: {
              id: module.tasks[0].id,
              timedOut: true
            }
          });
        }}
      >
        <AlarmIcon fontSize="small" />
      </TimeOutButton>
      <ActiveButton
        aria-label="time out"
        color={module.tasks[0].isActive ? "primary" : "default"}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          updateTask({
            variables: {
              id: module.tasks[0].id,
              isActive: true
            }
          });
        }}
      >
        <AccessibilityIcon fontSize="small" />
      </ActiveButton>
    </>
  ) : null;

  return (
    <TableRow
      hover
      className={isTask ? "pointer" : ""}
      key={index}
      onClick={() =>
        isTask
          ? history.push(
              blockId
                ? `/x/${cohortId}/block/${blockId}/module/${module.id}`
                : cohortId
                ? `/x/${cohortId}/module/${module.id}`
                : `/x/module/${module.id}`
            )
          : null
      }
    >
      <TableCell component="th" scope="row">
        {index + 1}
      </TableCell>
      <TableCell>{title}</TableCell>
      <TableCell>{actions}</TableCell>
      {filter && isTask ? (
        <TableCell padding="checkbox">
          <Checkbox color="primary" onClick={handleCheckboxClick} />
        </TableCell>
      ) : null}
    </TableRow>
  );
};
export default ModuleRow;
