import React from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Toolbar
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { useColorStyles } from "../../../ui/theme/colors";

const FAQ = ({ questions }) => {
  const colors = useColorStyles();

  if (questions.length === 0) return null;

  const questionPanels = questions.map(question => (
    <ExpansionPanel className="col-md-8 col-sm-12 mt-3">
      <ExpansionPanelSummary
        classes={{
          expanded: colors.primary
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>{question.question}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography>{question.answer}</Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  ));
  return (
    <>
      <Toolbar />
      <h2>
        <b>FAQs</b>
      </h2>
      {questionPanels}
    </>
  );
};

export default FAQ;
