import React from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Stepper,
  Step,
  StepButton,
  StepLabel,
  withWidth
} from "@material-ui/core";
import { useBgColorStyles } from "../../../ui/theme/colors";
import { CustomStepConnector, CustomStepIcon } from "../Styles";

const Blocks = ({ blocks, width }) => {
  const bgColors = useBgColorStyles();
  const history = useHistory();
  const { block } = useParams();

  const activeStep = block ? parseInt(block) : 0;

  return (
    <div className="scrolling-wrapper-flex">
      <Stepper
        orientation={width === "xs" ? "vertical" : "horizontal"}
        nonLinear
        activeStep={activeStep}
        alternativeLabel={width === "xs" ? false : true}
        color="secondary"
        className={bgColors.secondary}
        connector={<CustomStepConnector />}
      >
        {blocks.map((cohortBlock, index) => (
          <Step
            key={index}
            color="secondary"
            completed={cohortBlock.isCompleted && block !== index}
          >
            <StepButton
              onClick={() => history.push(`/dashboard/block/${index}`)}
            >
              <StepLabel StepIconComponent={CustomStepIcon}>
                {cohortBlock.block.title}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default withWidth()(Blocks);
