import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Toolbar,
  Typography,
  Divider,
  Grid,
  Hidden,
  IconButton
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useColorStyles } from "../../ui/theme/colors";

import { isAuthenticated } from "../../graphql/helpers/Authentication";

import SearchBar from "./SearchBar";
import MobileNav from "./MobileNav";
import Nav from "./Nav";
import LogoutButton from "./LogoutButton";

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 2
  }
}));

const Navbar = () => {
  const location = useLocation();
  const classes = useStyles();
  const colorClasses = useColorStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <Grid justify="center" container className="fixed-top bg-white">
      <Grid item xl={10} lg={11} sm={12} xs={12}>
        <Toolbar>
          <Grid container spacing={3} className={classes.title}>
            <Grid item>
              <Link to="/workshops">
                <Typography
                  variant="h5"
                  className={`poppins ${colorClasses.primaryDark}`}
                >
                  WAREHOUSE
                </Typography>
              </Link>
            </Grid>
            {isAuthenticated() ? (
              <Hidden mdDown>
                <Nav />
              </Hidden>
            ) : null}
          </Grid>
          {isAuthenticated() ? (
            <Grid container spacing={2} justify="flex-end">
              <Hidden mdDown>
                <Grid item>
                  <SearchBar />
                </Grid>
                <Grid item>
                  <LogoutButton />
                </Grid>
              </Hidden>
              <Hidden lgUp>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => setOpen(prev => !prev)}
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>
            </Grid>
          ) : null}
        </Toolbar>
        <Divider />
      </Grid>
      {isAuthenticated() ? (
        <Hidden lgUp>
          <MobileNav open={open} />
        </Hidden>
      ) : null}
    </Grid>
  );
};

export default withRouter(Navbar);
