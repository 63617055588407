import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation, useApolloClient } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import { REGISTER } from "../../../graphql/mutations/Authentication";
import { setUser } from "../../../graphql/helpers/Authentication";
import ErrorMessage from "../../common/ErrorMessage";
import {
  TextField,
  Button,
  Container,
  Box,
  Paper,
  Toolbar,
  Link
} from "@material-ui/core";

const Register = () => {
  const client = useApolloClient();
  const history = useHistory();
  const [error, setError] = useState(null);
  const [registerUser] = useMutation(REGISTER, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      const auth = data.register;
      setUser(auth.token, client);
      if (auth.success) {
        client.writeData({
          data: {
            me: { id: "", username: "", isStaff: false, __typename: "UserNode" }
          }
        });
        history.push("/workshops");
      } else {
        setError(auth.errors);
      }
    }
  });

  const handleSubmit = async e => {
    e.preventDefault();
    const data = new FormData(e.target);
    const input = {
      email: data.get("email"),
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      password1: data.get("password1"),
      password2: data.get("password2"),
      github: data.get("github", "")
    };
    try {
      await registerUser({ variables: input });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Container maxWidth="sm">
      <Helmet>
        <title>Register|Warehouse</title>
      </Helmet>
      <Toolbar />
      <Box px={4} py={2} component={Paper} variant="outlined">
        <form onSubmit={handleSubmit}>
          <TextField
            name="email"
            label="Email"
            type="email"
            variant="outlined"
            size="small"
            className="my-2"
            required
            fullWidth
          />
          {error && <ErrorMessage messages={error.username} />}
          <TextField
            name="firstName"
            label="First Name"
            variant="outlined"
            size="small"
            className="my-2"
            required
            fullWidth
          />
          <TextField
            name="lastName"
            label="Last Name"
            variant="outlined"
            size="small"
            className="my-2"
            required
            fullWidth
          />
          <TextField
            name="password1"
            label="Password"
            type="password"
            variant="outlined"
            size="small"
            className="my-2"
            required
            fullWidth
          />
          <TextField
            name="password2"
            label="Retype Password"
            type="password"
            variant="outlined"
            size="small"
            className="my-2"
            required
            fullWidth
          />
          {error && <ErrorMessage messages={error.password2} />}
          <TextField
            name="github"
            label="Github"
            variant="outlined"
            className="my-2"
            size="small"
            fullWidth
          />
          {error && <ErrorMessage messages={error.nonFieldErrors} />}
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className="my-2"
            fullWidth
          >
            Register
          </Button>
          <Link
            component="button"
            variant="body2"
            className="w-100"
            onClick={() => history.push("/auth/login")}
          >
            Login Page
          </Link>
          <Link
            component="button"
            variant="body2"
            className="w-100"
            onClick={() => history.push("/auth/reset-email")}
          >
            Forgot Password?
          </Link>
        </form>
      </Box>
    </Container>
  );
};

export default Register;
