import React from "react";
import { Card, Typography, Box } from "@material-ui/core";

import ColorLinearProgress from "../../common/ColorLinearProgress";

const BlockCompletion = ({ studentTasks }) => {
  const requiredTasks = studentTasks.filter(
    studentTask => studentTask.task.required
  );
  const completedTasks = studentTasks.filter(studentTask => studentTask.done);
  const completedRequiredTasks = completedTasks.filter(
    studentTask => studentTask.task.required
  );

  return (
    <Card className="mt-4">
      <Box mx={3} my={5} pr={3}>
        <Typography style={{ color: "#9A9AB0" }} className="mb-5">
          PROGRESS
        </Typography>
        <Typography className="mb-3">
          Block Completion
          <Typography className="float-right" color="primary" component="span">
            {studentTasks.length
              ? parseInt((completedTasks.length / studentTasks.length) * 100)
              : 100}
            %
          </Typography>
        </Typography>
        <ColorLinearProgress
          color="blue"
          value={
            studentTasks.length
              ? (completedTasks.length / studentTasks.length) * 100
              : 100
          }
        />
        <Typography className="mt-5 mb-3">
          Required Tasks Completion
          <Typography className="float-right" color="primary" component="span">
            {requiredTasks.length
              ? parseInt(
                  (completedRequiredTasks.length / requiredTasks.length) * 100
                )
              : 100}
            %
          </Typography>
        </Typography>
        <ColorLinearProgress
          value={
            requiredTasks.length
              ? parseInt(
                  (completedRequiredTasks.length / requiredTasks.length) * 100
                )
              : 100
          }
          color="blue"
        />
      </Box>
    </Card>
  );
};

export default BlockCompletion;
