import React from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/react-hooks";
import { useLocation, Link } from "react-router-dom";
import { Container, Typography, Toolbar } from "@material-ui/core";

import { SEARCH_WORKSHOPS } from "../../../graphql/Queries/Workshops";

import Loading from "../../common/Loading";
import Error from "../../common/Error";

const Search = () => {
  const query = new URLSearchParams(useLocation().search).get("q");
  const { data, loading, error } = useQuery(SEARCH_WORKSHOPS, {
    variables: query ? { query: query } : { query: "" }
  });

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  const sections = data.searchWorkshops;

  return (
    <Container>
      <Helmet>
        <title>Result|Warehouse</title>
      </Helmet>
      <Toolbar />
      <h5>Search Results For {query}</h5>
      {sections.length === 0 ? (
        <Typography color="primary" className="mt-2">
          No Result
        </Typography>
      ) : (
        sections.map(section => (
          <Link
            to={`/workshops/${section.chapter.workshop.slug}/${section.chapter.slug}/${section.slug}`}
          >
            <Typography
              color="primary"
              className="py-3 my-3 px-3 rounded"
              style={{ backgroundColor: "#F8F8FE" }}
            >
              {section.chapter.workshop.title} / {section.chapter.title} /{" "}
              {section.title}
            </Typography>
          </Link>
        ))
      )}
    </Container>
  );
};

export default Search;
