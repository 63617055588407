import React from "react";

const ErrorMessage = ({ messages }) => {
  return (
    <>
      {messages ? (
        <p className="text-danger">
          {messages.map(({ message }, i) => (
            <span key={i}>{message}</span>
          ))}
        </p>
      ) : null}
    </>
  );
};

export default ErrorMessage;
