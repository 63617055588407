import React from "react";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ComputerOutlinedIcon from "@material-ui/icons/ComputerOutlined";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

const useStyles = makeStyles(theme => ({
  done: { backgroundColor: "#EEF9EB", color: "#63C24A" },
  late: { backgroundColor: "#ffebee", color: "#c62828" },
  required: { backgroundColor: "#FEF8E7", color: "#EFB230" },
  assignment: { backgroundColor: "#F8F8FE", color: theme.palette.primary.main }
}));

const TaskStatus = ({ studentTask }) => {
  const classes = useStyles();

  let icon = null;
  let label = null;
  let className = null;

  if (studentTask.done) {
    icon = (
      <CheckBoxOutlinedIcon
        className={studentTask.score === 100 ? classes.done : classes.late}
      />
    );
    label = studentTask.score === 100 ? "Done" : "Late";
    className = studentTask.score === 100 ? classes.done : classes.late;
  } else if (studentTask.task.required) {
    icon = <ReportProblemOutlinedIcon className={classes.required} />;
    label = "Required";
    className = classes.required;
  } else {
    icon = <ComputerOutlinedIcon className={classes.assignment} />;
    label = "Assignment";
    className = classes.assignment;
  }

  return <Chip icon={icon} label={label} className={`pl-2 ${className}`} />;
};

export default TaskStatus;
