import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { ListItem, Typography } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const Section = ({ section, chapter }) => {
  let { slug, sectionSlug } = useParams();
  const history = useHistory();

  return (
    <ListItem
      button
      key={section.slug}
      onClick={() =>
        history.replace(`/workshops/${slug}/${chapter.slug}/${section.slug}`)
      }
    >
      <Typography color={sectionSlug === section.slug ? "primary" : "inherit"}>
        <ChevronRightIcon fontSize="small" />
        {section.title}
      </Typography>
    </ListItem>
  );
};

export default Section;
