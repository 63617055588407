import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation, useApolloClient } from "@apollo/react-hooks";
import { useHistory, useLocation } from "react-router-dom";
import {
  TextField,
  Button,
  Box,
  Paper,
  Container,
  Toolbar,
  Link
} from "@material-ui/core";

import { LOGIN } from "../../../graphql/mutations/Authentication";
import { setUser } from "../../../graphql/helpers/Authentication";
import ErrorMessage from "../../common/ErrorMessage";

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const client = useApolloClient();

  const params = new URLSearchParams(location.search);
  const [error, setError] = useState(null);

  const [loginUser] = useMutation(LOGIN, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      const auth = data.tokenAuth;
      setUser(auth.token, client);
      if (auth.success) {
        client.writeData({ data: { me: { ...auth.user } } });

        const redirect = params.get("redirect");
        if (redirect) history.push(redirect);
        else history.push("/workshops");
      } else {
        setError(auth.errors);
      }
    }
  });

  const handleSubmit = async e => {
    e.preventDefault();
    const data = new FormData(e.target);
    const input = {
      email: data.get("email"),
      password: data.get("password")
    };
    try {
      await loginUser({ variables: input });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Helmet>
        <title>Login|Warehouse</title>
      </Helmet>
      <Toolbar />
      <Box px={4} py={2} component={Paper} variant="outlined">
        <form onSubmit={handleSubmit}>
          <TextField
            name="email"
            label="email"
            variant="outlined"
            className="my-2"
            size="small"
            required
            fullWidth
          />
          <TextField
            name="password"
            type="password"
            label="password"
            variant="outlined"
            className="my-2"
            size="small"
            required
            fullWidth
          />
          {error && <ErrorMessage messages={error.nonFieldErrors} />}
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className="my-2"
            fullWidth
          >
            Login
          </Button>
          <Link
            component="button"
            variant="body2"
            className="w-100"
            onClick={() => history.push("/auth/register")}
          >
            Signup for an Account
          </Link>
          <Link
            component="button"
            variant="body2"
            className="w-100"
            onClick={() => history.push("/auth/reset-email")}
          >
            Forgot Password?
          </Link>
        </form>
      </Box>
    </Container>
  );
};

export default Login;
