import React from "react";
import { Grid, Button } from "@material-ui/core";

const Filter = ({ topics, selectedTopic, handleSelection }) => {
  const topicButtons = topics.map(topic => (
    <Grid item key={topic.title}>
      <Button
        className="text-nowrap"
        color="primary"
        variant={
          selectedTopic.toLowerCase() === topic.title.toLowerCase()
            ? "contained"
            : "text"
        }
        onClick={() => handleSelection(topic.title)}
      >
        {topic.title}
      </Button>
    </Grid>
  ));

  return (
    <Grid container spacing={3} className="mb-5 scrolling-wrapper-flex">
      <Grid item>
        <Button
          color="primary"
          variant={selectedTopic === "" ? "contained" : "text"}
          onClick={() => handleSelection("")}
        >
          All
        </Button>
      </Grid>
      {topicButtons}
    </Grid>
  );
};

export default Filter;
