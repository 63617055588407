import React from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/react-hooks";

// queries
import { WORKSHOPS } from "../../../graphql/Queries/Workshops";

// components
import List from "./List";
import Loading from "../../common/Loading";
import Error from "../../common/Error";

const WorkshopsList = () => {
  const { loading, error, data } = useQuery(WORKSHOPS);
  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  return (
    <>
      <Helmet>
        <title>Learn|Warehouse</title>
      </Helmet>
      <List
        workshops={data.workshops.filter(workshop => workshop.firstChapter)}
        topics={data.topics.filter(topic => topic.hasWorkshops)}
      />
    </>
  );
};

export default WorkshopsList;
