import React from "react";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import { useApolloClient } from "@apollo/react-hooks";

import { isStaff } from "../../graphql/helpers/Authentication";
import SearchBar from "./SearchBar";
import LogoutButton from "./LogoutButton";

const MobileNav = ({ open }) => {
  const location = useLocation();
  const history = useHistory();
  const client = useApolloClient();

  const handleClick = path => {
    history.push(path);
  };

  return (
    <>
      {open ? (
        <List component="nav" className="w-100 ml-3">
          <ListItem button onClick={() => handleClick("/workshops")}>
            <ListItemText
              primary="Workshops"
              color={
                location.pathname.includes("workshops") ? "primary" : "default"
              }
            />
          </ListItem>

          <ListItem button onClick={() => handleClick("/assignments")}>
            <ListItemText
              primary="Practice"
              color={
                location.pathname.includes("assignments")
                  ? "primary"
                  : "default"
              }
            />
          </ListItem>

          <ListItem button onClick={() => handleClick("/dashboard")}>
            <ListItemText
              primary="Dashboard"
              color={
                location.pathname.includes("dashboard") ? "primary" : "default"
              }
            />
          </ListItem>

          {isStaff(client) ? (
            <ListItem button onClick={() => handleClick("/x")}>
              <ListItemText
                primary="x"
                color={location.pathname.includes("x") ? "primary" : "default"}
              />
            </ListItem>
          ) : null}
          <ListItem>
            <SearchBar />
          </ListItem>
          <ListItem>
            <LogoutButton />
          </ListItem>
        </List>
      ) : null}
    </>
  );
};

export default MobileNav;
