import gql from "graphql-tag";

export const STUDENT_COHORTS = gql`
  query {
    studentCohorts {
      id
      title
      bootcamp
    }
  }
`;

export const STUDENT_COHORT = gql`
  query($cohortId: Int) {
    studentCohort(cohortId: $cohortId) {
      id
      title
      bootcamp
      cohortBlocks {
        isCompleted
        block {
          title
          modules {
            moduleType
            isWorkshop
            assignment {
              title
              slug
              githubRepoName
            }
            chapter {
              title
              slug
              workshop {
                title
                slug
              }
            }
            tasks(cohortId: $cohortId) {
              required
              timedOut
              studentTask {
                score
                done
                timeDone
              }
            }
          }
        }
      }
    }
  }
`;

export const COHORTS = gql`
  query {
    cohorts {
      id
      title
      bootcamp
    }
  }
`;

export const COHORT = gql`
  query($cohortId: Int) {
    cohort(cohortId: $cohortId) {
      id
      title
      bootcamp
      students {
        id
        name
        github
        studentTasks(cohortId: $cohortId) {
          id
          score
          done
          timeDone
          notes
          task {
            id
            required
            module {
              id
              block {
                id
              }
              assignment {
                id
                title
                slug
                githubRepoName
              }
            }
          }
        }
      }
      cohortBlocks {
        id
        isCompleted
        percentages {
          done
          pushedIntime
        }
        block {
          id
          title
          modules {
            id
            moduleType
            isWorkshop
            assignment {
              id
              title
              slug
              githubRepoName
              activeSolution
            }
            chapter {
              title
              slug
              workshop {
                title
                slug
              }
            }
            tasks(cohortId: $cohortId) {
              id
              required
              timedOut
              isActive
            }
          }
        }
      }
    }
  }
`;

export const MODULE = gql`
  query($moduleId: Int!, $cohortId: Int!) {
    module(moduleId: $moduleId) {
      id
      assignment {
        id
        title
        slug
        githubRepoName
        activeSolution
      }
      tasks(cohortId: $cohortId) {
        id
        required
        timedOut
        studentTasks {
          id
          score
          done
          timeDone
          notes
          student {
            id
            name
            github
          }
        }
      }
    }
  }
`;

export const COHORT_MINI = gql`
  query($cohortId: Int) {
    cohort(cohortId: $cohortId) {
      id
      title
      bootcamp
      cohortBlocks {
        id
        isCompleted
        percentages {
          done
          pushedIntime
        }
        block {
          id
          title
          modules {
            id
            moduleType
            isWorkshop
            assignment {
              id
              title
              slug
              githubRepoName
              activeSolution
            }
            chapter {
              title
              slug
              workshop {
                title
                slug
              }
            }
            tasks(cohortId: $cohortId) {
              id
              required
              timedOut
            }
          }
        }
      }
    }
  }
`;
