import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/react-hooks";
import {
  Container,
  TextField,
  MenuItem,
  Typography,
  Toolbar,
  Box
} from "@material-ui/core";

import { STUDENT_COHORTS } from "../../graphql/Queries/Dashboard";
import { useBgColorStyles } from "../../ui/theme/colors";
import Cohort from "./Cohort";
import Loading from "../common/Loading";
import Error from "../common/Error";

const Dashboard = () => {
  const bgColors = useBgColorStyles();
  const [selectedCohort, setSelectedCohort] = useState(0);
  const { loading, error, data } = useQuery(STUDENT_COHORTS);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  const cohorts = data.studentCohorts;

  if (cohorts.length === 0)
    return (
      <Typography
        component={Box}
        marginTop={5}
        color="primary"
        align="center"
        variant="h5"
      >
        You're still not added to a cohort
      </Typography>
    );

  return (
    <Box className={bgColors.secondary} minHeight={"100vh"}>
      <Helmet>
        <title>Dashbaord|Warehouse</title>
      </Helmet>
      <Container>
        <Typography
          variant="overline"
          className="d-block text-muted pt-2 pt-sm-4"
        >
          BOOTCAMP
        </Typography>
        <TextField
          className="bg-white"
          size="small"
          select
          variant="outlined"
          value={selectedCohort}
          onChange={e => setSelectedCohort(e.target.value)}
        >
          {cohorts.map((cohort, index) => (
            <MenuItem key={cohort.id} value={index}>
              {cohort.bootcamp}-{cohort.title}
            </MenuItem>
          ))}
        </TextField>
        <Cohort selectedCohort={cohorts[selectedCohort].id} />
      </Container>
      <Toolbar />
    </Box>
  );
};

export default Dashboard;
