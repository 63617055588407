import React from "react";
import { Helmet } from "react-helmet";

import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import { Container, Toolbar } from "@material-ui/core";

// queries
import { ASSIGNMENT } from "../../../graphql/Queries/Assignments";

import Content from "./Content";
import References from "./Refrences";
import FAQ from "./FAQ";
import Solution from "./Solution";
import Loading from "../../common/Loading";
import Error from "../../common/Error";

const AssignmentDetail = () => {
  let { slug } = useParams();

  const { loading, error, data } = useQuery(ASSIGNMENT, {
    variables: { assignmentSlug: slug }
  });

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  const { assignment } = data;

  return (
    <Container>
      <Helmet>
        <title>{assignment.title}|Warehouse</title>
      </Helmet>
      <Toolbar />
      <Content assignment={assignment} />
      <References references={assignment.warehouseReferences} />
      <Solution
        solution={assignment.solution}
        active={assignment.activeSolution}
      />
      <FAQ questions={assignment.FAQs} />
    </Container>
  );
};

export default AssignmentDetail;
