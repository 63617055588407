import React from "react";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead
} from "@material-ui/core";

import ModuleRow from "./ModuleRow";

const CustomTableCell = withStyles({
  head: {
    color: "#9A9AB0",
    backgroundColor: "white"
  }
})(TableCell);

const ModulesTable = ({ modules, width }) => {
  const headers =
    width === "xs" ? ["#", "NAME", "TYPE"] : ["#", "NAME", "TYPE", "PROGRESS"];
  const rows = modules.map((module, index) => (
    <ModuleRow module={module} index={index} key={index} />
  ));
  return (
    <TableContainer component={Paper} className="mb-4">
      <div className="scroll-table-y">
        <Table stickyHeader>
          <TableHead>
            <TableRow className="percentage-row">
              {headers.map(column => (
                <CustomTableCell key={column}>{column}</CustomTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </div>
    </TableContainer>
  );
};

export default withWidth()(ModulesTable);
