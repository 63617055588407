import React, { useState } from "react";

import TypeFilters from "./TypeFilters";
import ModulesTable from "./ModulesTable";

const Curriculum = ({ modules }) => {
  const [moduleType, setModuleType] = useState("");

  const filteredModules = moduleType
    ? modules.filter(_module => _module.moduleType === moduleType)
    : modules;

  return (
    <>
      <TypeFilters setModuleType={setModuleType} moduleType={moduleType} />
      <ModulesTable modules={filteredModules} />
    </>
  );
};

export default Curriculum;
