import React from "react";
import { Grid, Button } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import { useApolloClient } from "@apollo/react-hooks";

import { isStaff } from "../../graphql/helpers/Authentication";

const Nav = () => {
  const location = useLocation();
  const client = useApolloClient();

  return (
    <>
      <Grid item>
        <Link to="/workshops">
          <Button
            color={
              location.pathname.includes("workshops") ? "primary" : "default"
            }
          >
            Learn
          </Button>
        </Link>
      </Grid>
      <Grid item>
        <Link to="/assignments">
          <Button
            color={
              location.pathname.includes("assignments") ? "primary" : "default"
            }
          >
            Practice
          </Button>
        </Link>
      </Grid>
      <Grid item>
        <Link to="/dashboard">
          <Button
            color={
              location.pathname.includes("dashboard") ? "primary" : "default"
            }
          >
            Dashboard
          </Button>
        </Link>
      </Grid>
      {isStaff(client) ? (
        <Grid item>
          <Link to="/x">
            <Button
              color={location.pathname.includes("x") ? "primary" : "default"}
            >
              x
            </Button>
          </Link>
        </Grid>
      ) : null}
    </>
  );
};

export default Nav;
