import gql from "graphql-tag";

export const WORKSHOPS = gql`
  query {
    topics {
      title
      hasWorkshops
    }
    workshops {
      slug
      title
      image
      description
      topics {
        title
      }
      firstChapter {
        slug
        firstSection {
          slug
        }
      }
    }
  }
`;

export const WORKSHOP = gql`
  query($workshopSlug: String!) {
    workshop(workshopSlug: $workshopSlug) {
      id
      title
      image
      description
      topics {
        title
      }
      chapters {
        slug
        title
        position
        sections {
          slug
          title
          content
          position
        }
      }
    }
  }
`;

export const SEARCH_WORKSHOPS = gql`
  query($query: String!) {
    searchWorkshops(query: $query) {
      slug
      title
      chapter {
        slug
        title
        workshop {
          slug
          title
        }
      }
    }
  }
`;
