import gql from "graphql-tag";

export const UPDATE_ASSIGNMENT = gql`
  mutation($assignmentSlug: String!, $activeSolution: Boolean) {
    updateAssignment(slug: $assignmentSlug, activeSolution: $activeSolution) {
      assignment {
        id
        challengeDetail
        activeSolution
        activeChallenge
      }
    }
  }
`;
