import React from "react";
import {
  Table,
  TableBody,
  TableContainer,
  Paper,
  TableHead,
  TableCell,
  Typography,
  TableRow
} from "@material-ui/core";

import StudentRow from "./StudentRow";

const Students = ({ students, selectedModules, cohortId }) => {
  const filteredStudents = students.filter(student =>
    student.studentTasks.some(
      studentTask =>
        !studentTask.done &&
        selectedModules.includes(studentTask.task.module.id)
    )
  );

  const studentRows =
    selectedModules.length !== 0
      ? filteredStudents.map((student, index) => (
          <StudentRow student={student} cohortId={cohortId} key={index} />
        ))
      : students.map((student, index) => (
          <StudentRow student={student} cohortId={cohortId} key={index} />
        ));

  return (
    <TableContainer component={Paper} className="mb-4">
      <div className="scroll-table-y">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell colSpan={4}>
                <Typography variant="h6">STUDENTS</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{studentRows}</TableBody>
        </Table>
      </div>
    </TableContainer>
  );
};

export default Students;
