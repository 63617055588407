import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { List, ListItem, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CodeIcon from "@material-ui/icons/Code";
import RemoveIcon from "@material-ui/icons/Remove";

import { useStyles } from "../styles";
import Chapter from "./Chapter";

const Sidebar = ({ workshopTitle, chapters, mobile }) => {
  let { chapterSlug } = useParams();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(chapterSlug);

  const handleChange = panel => (e, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const chapterPanels = chapters.map(chapter => (
    <Chapter
      chapter={chapter}
      key={chapter.slug}
      expanded={expanded}
      handleChange={handleChange}
    />
  ));

  return (
    <div
      className={mobile ? "mb-4 w-100" : "scrolling-wrapper-vertical"}
      style={mobile ? {} : { width: "280px" }}
    >
      <List disablePadding>
        <ListItem
          className={classes.listItem}
          onClick={() => setOpen(prev => !prev)}
        >
          <Typography className="py-2 flex-grow">
            <CodeIcon className="mr-3" />
            {workshopTitle}
          </Typography>
          {mobile ? open ? <RemoveIcon /> : <AddIcon /> : null}
        </ListItem>
      </List>
      {mobile ? (open ? chapterPanels : null) : chapterPanels}
    </div>
  );
};

export default Sidebar;
