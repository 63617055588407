import React from "react";
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Divider
} from "@material-ui/core";
import { Link, useRouteMatch } from "react-router-dom";
import { useColorStyles } from "../../../ui/theme/colors";

import TopicCard from "../../common/TopicCard";

const AssignmentCard = ({ assignment }) => {
  const match = useRouteMatch();
  const colorClasses = useColorStyles();

  return (
    <Grid item lg={3} md={4} sm={6} xs={12}>
      <Link to={`${match.path}/${assignment.slug}`}>
        <Card elevation={3}>
          <CardActionArea>
            <CardContent>
              <p className={colorClasses.primaryDark}>
                <b>{assignment.title}</b>
              </p>
              <Divider className="mb-2" />
              {assignment.topics.map(topic => (
                <TopicCard key={topic.title} text={topic.title} />
              ))}
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    </Grid>
  );
};

export default AssignmentCard;
