import React from "react";
import { useHistory, useParams } from "react-router-dom";

import { Grid, Fab, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const Footer = ({ currentChapter, currentSection, chapters }) => {
  let { slug } = useParams();
  const history = useHistory();

  let nextSection = currentChapter.sections.find(
    section => currentSection.position + 1 === section.position
  );
  let nextChapter = currentChapter;
  if (!nextSection) {
    nextChapter = chapters.find(
      chapter => currentChapter.position + 1 === chapter.position
    );
    if (nextChapter) nextSection = nextChapter.sections[0];
  }

  let prevSection = null;
  let prevChapter = currentChapter;
  if (currentSection.position !== 1)
    prevSection = currentChapter.sections.find(
      section => currentSection.position - 1 === section.position
    );
  else if (currentChapter.position !== 1) {
    prevChapter = chapters.find(
      chapter => currentChapter.position - 1 === chapter.position
    );
    prevSection = prevChapter.sections[0];
  }

  const next = () => {
    history.replace(
      `/workshops/${slug}/${nextChapter.slug}/${nextSection.slug}`
    );
  };

  const previous = () => {
    history.replace(
      `/workshops/${slug}/${prevChapter.slug}/${prevSection.slug}`
    );
  };

  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item>
        {prevSection ? (
          <div onClick={previous} className="pointer">
            <Fab
              size="medium"
              color="secondary"
              disableRipple
              disableFocusRipple
              className="shadow-none"
            >
              <ArrowBackIcon color="primary" />
            </Fab>
            <Typography
              className="ml-2 d-none d-sm-inline"
              component="span"
              color="primary"
            >
              {prevSection.title}
            </Typography>
          </div>
        ) : null}
      </Grid>
      <Grid item>
        {nextSection ? (
          <div onClick={next} className="pointer">
            <Typography
              className="mr-2 d-none d-sm-inline"
              component="span"
              color="primary"
            >
              {nextSection.title}
            </Typography>
            <Fab
              size="medium"
              color="secondary"
              disableRipple
              disableFocusRipple
              className="shadow-none"
            >
              <ArrowForwardIcon />
            </Fab>
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default Footer;
