import React from "react";
import { useHistory } from "react-router-dom";
import { useApolloClient } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { logout } from "../../graphql/helpers/Authentication";

const useStyles = makeStyles(theme => ({
  logout: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    },
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.light
  }
}));

const LogoutButton = () => {
  const history = useHistory();
  const client = useApolloClient();
  const classes = useStyles();

  const handleLogout = () => {
    logout(client);
    history.push("/auth/login");
    client.clearStore();
  };
  return (
    <Button
      variant="outlined"
      className={classes.logout}
      onClick={handleLogout}
    >
      <ExitToAppIcon className="mr-2" /> Log out
    </Button>
  );
};

export default LogoutButton;
