import { StepIcon, StepConnector } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const CustomStepIcon = withStyles(theme => ({
  root: {
    width: "40px",
    height: "40px",
    border: "2px solid #C7C7DB",
    color: "white",
    borderRadius: "50%",
    boxSizing: "border-box",
    boxShadow: "0px 8px 16px rgba(226, 226, 237, 0.8)"
  },
  text: {
    fill: "#C7C7DB",
    fontStyle: "normal",
    fontSize: "14px"
  },
  active: {
    border: "none",
    "& $text": {
      fill: "white"
    }
  },
  completed: {
    border: "none",
    "& $text": {
      fill: "white"
    }
  }
}))(StepIcon);

export const CustomStepConnector = withStyles(theme => ({
  horizontal: {
    top: 20
  },
  vertical: {
    padding: "0px",
    marginLeft: "18px"
  },

  completed: {
    "& $line": {
      borderColor: theme.palette.primary.main
    }
  },
  line: {
    borderColor: "#C7C7DB",
    borderTopWidth: 2,
    borderRadius: 1
  }
}))(StepConnector);
