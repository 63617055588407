import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Box,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid
} from "@material-ui/core";

import ColorLinearProgress from "../../common/ColorLinearProgress";

const RequiredCard = ({ taskModules }) => {
  const incompleteTasks = taskModules
    .filter(
      module => module.tasks[0].required && !module.tasks[0].studentTask.done
    )
    .map(module => (
      <TableRow>
        <TableCell>
          <Link
            to={`/assignments/${module.assignment.slug}`}
            key={module.assignment.slug}
          >
            <span className="text-dark">{module.assignment.title}</span>
          </Link>
        </TableCell>
        <TableCell align="right">
          <Grid container spacing={1}>
            <Grid item xs={8} className="my-auto ml-auto">
              <ColorLinearProgress
                color="blue"
                value={module.tasks[0].studentTask.score}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" style={{ color: "#5A5A7E" }}>
                {module.tasks[0].studentTask.score}%
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    ));
  return (
    <Card className="mt-4">
      <Box mx={3} my={5} pr={3}>
        <Typography style={{ color: "#9A9AB0" }} className="mb-4">
          INCOMPLETE TASKS
        </Typography>
        {incompleteTasks.length === 0 ? (
          <p className="text-muted text-center">All tasks are completed</p>
        ) : null}
        <Table>
          <TableBody>{incompleteTasks}</TableBody>
        </Table>
      </Box>
    </Card>
  );
};

export default RequiredCard;
