import React from "react";
import { LinearProgress, Box, Typography } from "@material-ui/core";

const LinearLoading = () => {
  return (
    <Box width="80%" marginY={20} marginX="auto">
      <Typography variant="h5" color="primary" align="center">
        LOADING...
      </Typography>
      <LinearProgress />
    </Box>
  );
};

export default LinearLoading;
