import React from "react";
import { Box, Typography } from "@material-ui/core";

const Footer = () => {
  return (
    <Box
      boxShadow={3}
      textAlign="center"
      width="100%"
      style={{
        height: "40px",
        bottom: "0",
        position: "fixed",
        backgroundColor: "white"
      }}
    >
      <Typography className="text-muted mt-2" variant="caption" component="p">
        2020 © Copyright Reserved{" "}
        <Typography component="span" color="primary" variant="caption">
          CODED | Terms & Conditions
        </Typography>
      </Typography>
    </Box>
  );
};

export default Footer;
