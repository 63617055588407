import React from "react";
import { withRouter, Switch, Route, useRouteMatch } from "react-router-dom";
import { Container, Toolbar, Hidden } from "@material-ui/core";

// components
import WorkshopsList from "./List";
import WorkshopDetail from "./Detail";
import Search from "./Search";

const Workshops = () => {
  const match = useRouteMatch();

  return (
    <Container>
      <Switch>
        <Route exact path={`${match.path}/search`}>
          <Search />
        </Route>
        <Route
          path={[
            `${match.path}/:slug/:chapterSlug/:sectionSlug`,
            `${match.path}/:slug/:chapterSlug/`,
            `${match.path}/:slug/`
          ]}
        >
          <Hidden smDown>
            <Toolbar />
          </Hidden>
          <WorkshopDetail />
        </Route>

        <Route path={`${match.path}`}>
          <Toolbar />
          <WorkshopsList />
        </Route>
      </Switch>
      <Toolbar />
    </Container>
  );
};

export default withRouter(Workshops);
