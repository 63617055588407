import React from "react";
import { Switch, Route, useRouteMatch, withRouter } from "react-router-dom";

import Login from "./Login";
import Register from "./Register";
import ResetPasswordEmail from "./ResetPasswordEmail";
import ResetPassword from "./ResetPassword";

const Authentication = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/login`}>
        <Login />
      </Route>
      <Route path={`${match.path}/register`}>
        <Register />
      </Route>
      <Route path={`${match.path}/reset-email`}>
        <ResetPasswordEmail />
      </Route>
      <Route path={`${match.path}/password-reset/:token`}>
        <ResetPassword />
      </Route>
    </Switch>
  );
};

export default withRouter(Authentication);
