import React from "react";
import { List, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanel
} from "../styles";
import Section from "./Section";

const Chapter = ({ chapter, expanded, handleChange }) => {
  return (
    <ExpansionPanel
      key={chapter.slug}
      expanded={expanded === chapter.slug}
      onChange={handleChange(chapter.slug)}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{chapter.title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <List className="w-100">
          {chapter.sections.map(section => (
            <Section section={section} chapter={chapter} key={section.slug} />
          ))}
        </List>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Chapter;
