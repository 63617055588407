import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, IconButton, Grid } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";

import { Button } from "../Styles";
import { MODULE_TYPE } from "../../../constants";
import ModuleTable from "./ModuleTable";
import ColorLinearProgress from "../../common/ColorLinearProgress";

const BlockContent = ({
  modules,
  percentages,
  setSelectedModules,
  selectedModules
}) => {
  const [moduleType, setModuleType] = useState("");
  const [filter, setFilter] = useState(false);
  const location = useLocation();

  const filteredModules = moduleType
    ? modules.filter(module => module.moduleType === moduleType)
    : modules;

  return (
    <Box p={1} mb={5}>
      <center>
        <Box display="flex" alignItems="center" mb={4} mt={2}>
          <Box width="100%" mr={6}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              className="small text-muted mb-2"
            >
              <span>Finished </span>
              <span>{percentages.done}%</span>
            </Grid>
            <ColorLinearProgress value={percentages.done} color="blue" />
          </Box>
          <Box width="100%" mr={1}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              className="small text-muted mb-2"
            >
              <span>Pushed</span>
              <span>{percentages.pushedIntime}%</span>
            </Grid>
            <ColorLinearProgress
              value={percentages.pushedIntime}
              color="blue"
            />
          </Box>
        </Box>
      </center>
      <Grid
        container
        className={`rounded `}
        style={{ backgroundColor: "#F7F7FF" }}
      >
        <Box p={1}>
          <Button
            className="mx-1"
            color="primary"
            variant={moduleType === "" ? "contained" : "text"}
            onClick={() => setModuleType("")}
          >
            All
          </Button>
          <Button
            className="mx-1"
            color="primary"
            variant={moduleType === MODULE_TYPE.TASK ? "contained" : "text"}
            onClick={() => setModuleType(MODULE_TYPE.TASK)}
          >
            Tasks
          </Button>
          <Button
            className="mx-1"
            color="primary"
            variant={moduleType === MODULE_TYPE.WORKSHOP ? "contained" : "text"}
            onClick={() => setModuleType(MODULE_TYPE.WORKSHOP)}
          >
            Workshops
          </Button>
        </Box>
        <span className="flex-grow" />
        {location.pathname.includes("module") ? null : (
          <IconButton
            onClick={() => {
              setFilter(prev => !prev);
              setSelectedModules([]);
            }}
            color={filter ? "primary" : "default"}
          >
            <FilterListIcon />
          </IconButton>
        )}
      </Grid>
      <ModuleTable
        filteredModules={filteredModules}
        filter={filter}
        setSelectedModules={setSelectedModules}
        selectedModules={
          location.pathname.includes("module") ? [] : selectedModules
        }
      />
    </Box>
  );
};

export default BlockContent;
