import React from "react";
import { Helmet } from "react-helmet";
import gql from "graphql-tag";

import { useQuery } from "@apollo/react-hooks";
import { useParams, Link } from "react-router-dom";
import {
  Container,
  Grid,
  Toolbar,
  Button,
  Box,
  Paper
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import LinearLoading from "../../common/LinearLoading";
import Error from "../../common/Error";
import Blocks from "./Blocks";
import Tasks from "./Tasks";
import StudentInfo from "./StudentInfo";
import BlockCompletion from "./BlockCompletion";
import IncompleteTasks from "./IncompleteTasks";

export const STUDENT_COHORT = gql`
  query($cohortId: Int, $blockId: Int, $studentId: Int!) {
    cohort(cohortId: $cohortId) {
      id
      title
      bootcamp
      cohortBlocks {
        id
        isCompleted
        block {
          id
          title
        }
      }
    }

    student(studentId: $studentId) {
      id
      name
      github
      studentTasks(cohortId: $cohortId, blockId: $blockId) {
        id
        score
        done
        timeDone
        notes
        task {
          id
          required
          module {
            id
            block {
              id
            }
            assignment {
              id
              title
              slug
              githubRepoName
            }
          }
        }
      }
    }
  }
`;

const Student = () => {
  let { cohortId, studentId, blockId } = useParams();
  const { loading, error, data } = useQuery(STUDENT_COHORT, {
    variables: { cohortId, blockId, studentId }
  });

  if (loading) return <LinearLoading />;
  if (error) return <Error error={error} />;

  const { cohort, student } = data;

  const backLink = blockId
    ? `/x/${cohortId}/block/${blockId}`
    : cohortId
    ? `/x/${cohortId}`
    : "/x";

  return (
    <Container>
      <Helmet>
        <title>{student.name}|Warehouse</title>
      </Helmet>
      <Toolbar />
      <Link to={backLink}>
        <Button>
          <ArrowBackIosIcon fontSize="small" />
          Back
        </Button>
      </Link>
      <Grid container spacing={4}>
        <Grid item lg={8} xs={12}>
          <Paper>
            <Box pb={3} style={{ backgroundColor: "white" }}>
              <Blocks cohortBlocks={cohort.cohortBlocks} />
            </Box>
            <Tasks studentTasks={student.studentTasks} />
          </Paper>
        </Grid>
        <Grid item lg={4} xs={12}>
          <StudentInfo student={student} />
          <BlockCompletion studentTasks={student.studentTasks} />
          <IncompleteTasks studentTasks={student.studentTasks} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Student;
