import React, { useState } from "react";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";

const ManualScoring = ({ updateStudentTask, studentTask }) => {
  const [open, setOpen] = useState(false);

  const handleFormSubmit = e => {
    e.preventDefault();
    const form = new FormData(e.target);
    const input = {
      id: studentTask.id,
      score: form.get("score"),
      done: form.get("done") !== null
    };
    updateStudentTask({ variables: input });
    setOpen(false);
  };

  const handleDoneSubmit = e => {
    e.preventDefault();
    try {
      updateStudentTask({
        variables: { id: studentTask.id, score: 100, done: true }
      });
    } catch (error) {
      console.log(error);
    }

    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <AssignmentTurnedInIcon fontSize="small" />
      </IconButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <form onSubmit={handleFormSubmit}>
          <DialogTitle id="form-dialog-title">Manual Scoring</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Score"
              type="number"
              fullWidth
              required
              name="score"
            />
            <FormControlLabel
              control={<Checkbox name="done" color="primary" />}
              label="Done"
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" type="submit">
              Submit
            </Button>
            <Button color="primary" type="button" onClick={handleDoneSubmit}>
              100% done
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ManualScoring;
