import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Box,
  Table,
  Typography,
  TableRow,
  TableCell,
  Grid,
  TableBody
} from "@material-ui/core";

import ColorLinearProgress from "../../common/ColorLinearProgress";

const IncompleteTasks = ({ studentTasks }) => {
  const incompleteTasks = studentTasks
    .filter(studentTask => studentTask.task.required && !studentTask.done)
    .map(studentTask => (
      <TableRow key={studentTask.id}>
        <TableCell>
          <Link to={`/assignments/${studentTask.task.module.assignment.slug}`}>
            <span className="text-dark">
              {studentTask.task.module.assignment.title}
            </span>
          </Link>
        </TableCell>
        <TableCell align="right">
          <Grid container spacing={1}>
            <Grid item xs={8} className="my-auto ml-auto">
              <ColorLinearProgress color="blue" value={studentTask.score} />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" style={{ color: "#5A5A7E" }}>
                {studentTask.score}%
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    ));

  return (
    <Card className="mt-4">
      <Box mx={3} my={5} pr={3}>
        <Typography style={{ color: "#9A9AB0" }} className="mb-4">
          INCOMPLETE TASKS
        </Typography>
        {incompleteTasks.length === 0 ? (
          <p className="text-muted text-center">All tasks are completed</p>
        ) : null}
        <Table>
          <TableBody>{incompleteTasks}</TableBody>
        </Table>
      </Box>
    </Card>
  );
};

export default IncompleteTasks;
