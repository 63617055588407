import React from "react";
import { Tabs, Tab } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";

const Blocks = ({ cohortBlocks }) => {
  const { cohortId, blockId } = useParams();
  const history = useHistory();

  const tabs = cohortBlocks.map((cohortBlock, index) => (
    <Tab
      label={cohortBlock.block.title}
      value={cohortBlock.block.id}
      key={index}
    />
  ));

  const selectedBlock = blockId
    ? blockId
    : cohortBlocks.find(cohortBlock => !cohortBlock.isCompleted).block.id;

  return (
    <Tabs
      value={selectedBlock}
      onChange={(event, newValue) =>
        cohortId
          ? history.push(`/x/${cohortId}/block/${newValue}`)
          : history.push(`/x/block/${newValue}`)
      }
      variant="scrollable"
      scrollButtons="on"
      indicatorColor="primary"
      textColor="primary"
      className="border-bottom"
    >
      {tabs}
    </Tabs>
  );
};

export default Blocks;
