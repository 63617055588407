import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Grid,
  Typography
} from "@material-ui/core";
import { UPDATE_STUDENT_TASK } from "../../../graphql/mutations/Dashboard";

import ColorLinearProgress from "../../common/ColorLinearProgress";
import Note from "../common/Note";
import TaskStatus from "../common/TaskStatus";

const CustomTableCell = withStyles({
  head: {
    color: "#9A9AB0",
    backgroundColor: "white"
  }
})(TableCell);

const Tasks = ({ studentTasks, width }) => {
  const [updateStudentTask] = useMutation(UPDATE_STUDENT_TASK);

  const headers =
    width === "xs" ? ["NAME", "TYPE", ""] : ["NAME", "TYPE", "PROGRESS", ""];

  const progress = studentTask => (
    <Grid container spacing={1}>
      <Grid item xs={7} className="my-auto ml-auto">
        <ColorLinearProgress value={studentTask.score} color="blue" />
      </Grid>
      <Grid item xs={5}>
        <Typography variant="body2" style={{ color: "#5A5A7E" }}>
          {studentTask.score}%
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <div className="scroll-table-y">
      <Table stickyHeader>
        <TableHead>
          <TableRow className="percentage-row">
            {headers.map(column => (
              <CustomTableCell key={column}>{column}</CustomTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {studentTasks.map(studentTask => (
            <TableRow key={studentTask.id}>
              <TableCell>{studentTask.task.module.assignment.title}</TableCell>
              <TableCell>
                <TaskStatus studentTask={studentTask} />
                {width === "xs" ? (
                  <div className="mt-2"> {progress(studentTask)} </div>
                ) : null}
              </TableCell>
              {width === "xs" ? null : (
                <TableCell>{progress(studentTask)}</TableCell>
              )}
              <TableCell padding="checkbox">
                <Note
                  studentTask={studentTask}
                  updateStudentTask={updateStudentTask}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default withWidth()(Tasks);
