import React from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import { Grid, Hidden } from "@material-ui/core";

import { WORKSHOP } from "../../../graphql/Queries/Workshops";

import Content from "./Content";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Loading from "../../common/Loading";
import Error from "../../common/Error";

const WorkshopDetail = () => {
  let { slug, chapterSlug, sectionSlug } = useParams();
  const { loading, error, data } = useQuery(WORKSHOP, {
    variables: { workshopSlug: slug }
  });

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  const { workshop } = data;

  let chapter = null;
  let section = null;

  if (chapterSlug) {
    chapter = workshop.chapters.find(chapter => chapter.slug === chapterSlug);
  } else {
    chapter = workshop.chapters[0];
  }

  if (sectionSlug) {
    section = chapter.sections.find(section => section.slug === sectionSlug);
  } else {
    section = chapter.sections[0];
  }

  return (
    <Grid container spacing={5} justify="space-around">
      <Helmet>
        <title>{workshop.title}|Warehouse</title>
      </Helmet>
      <Grid item lg={3} md={4}>
        <Hidden smDown>
          <Sidebar
            workshopTitle={workshop.title}
            chapters={workshop.chapters}
          />
        </Hidden>
      </Grid>
      <Grid item lg={9} md={8} xs={12}>
        <Hidden mdUp>
          <Sidebar
            workshopTitle={workshop.title}
            chapters={workshop.chapters}
            mobile={true}
          />
        </Hidden>
        <Content section={section} />
        <Footer
          chapters={workshop.chapters}
          currentChapter={chapter}
          currentSection={section}
        />
      </Grid>
    </Grid>
  );
};

export default WorkshopDetail;
