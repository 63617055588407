import React from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/react-hooks";

import { ASSIGNMENT_TYPES } from "../../../graphql/Queries/Assignments";
import Loading from "../../common/Loading";
import Error from "../../common/Error";
import List from "./List";

const AssignmentList = () => {
  const { loading, error, data } = useQuery(ASSIGNMENT_TYPES);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <>
      <Helmet>
        <title>Practice|Warehouse</title>
      </Helmet>
      <List
        assignmentTypes={data.assignmentTypes}
        topics={data.topics.filter(topic => topic.hasAssignments)}
      />
    </>
  );
};

export default AssignmentList;
