import gql from "graphql-tag";

export const ASSIGNMENT_TYPES = gql`
  query {
    topics {
      title
      hasAssignments
    }
    assignmentTypes {
      title
      assignments {
        title
        slug
        topics {
          id
          title
        }
      }
    }
  }
`;

export const ASSIGNMENTS = gql`
  query {
    assignments {
      slug
      title
      assignmentType {
        title
      }
      topics {
        title
      }
    }
  }
`;

export const ASSIGNMENT = gql`
  query($assignmentSlug: String!) {
    assignment(assignmentSlug: $assignmentSlug) {
      id
      title
      challengeDetail
      solution
      activeSolution
      activeChallenge
      warehouseReferences {
        title
        slug
        chapter {
          title
          slug
          workshop {
            title
            slug
          }
        }
      }
      assignmentType {
        title
      }
      topics {
        title
      }
      FAQs {
        question
        answer
      }
    }
  }
`;
