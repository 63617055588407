import React, { useState } from "react";
import { useLocation, useHistory, useRouteMatch } from "react-router-dom";

import AssignmentType from "./AssignmentType";
import Filter from "./Filter";

const List = ({ assignmentTypes, topics }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const topic = params.get("topic");

  const [selectedTopic, setSelectedTopic] = useState(
    topic ? topic : topics.length === 0 ? "" : topics[0].title
  );
  const bgs = ["transparent", "#F1F1FB"];

  const assignmentTypesBlocks = assignmentTypes
    .filter(assignmentType => {
      const assignments =
        selectedTopic === ""
          ? assignmentType.assignments
          : assignmentType.assignments.filter(assignment =>
              assignment.topics.some(topic => topic.title === selectedTopic)
            );
      return assignments.length !== 0;
    })
    .map((assignmentType, index) => (
      <AssignmentType
        assignmentType={assignmentType}
        bg={bgs[index % 2]}
        selectedTopic={selectedTopic}
        key={index}
      />
    ));

  const handleTopicSelection = topic => {
    setSelectedTopic(topic);
    history.push(`${match.path}?topic=${topic}`);
  };

  return (
    <center>
      <Filter
        topics={topics}
        handleSelection={handleTopicSelection}
        selectedTopic={selectedTopic}
      />
      {assignmentTypesBlocks}
    </center>
  );
};

export default List;
