import React from "react";
import ReactMarkdown from "react-markdown";
import CodeBlock from "../../common/CodeBlock";

const Content = ({ assignment }) => {
  return (
    <>
      <h2>
        <b>{assignment.title}</b>
      </h2>
      <ReactMarkdown
        source={assignment.challengeDetail}
        renderers={{ code: CodeBlock }}
      />
    </>
  );
};

export default Content;
